<template>
    <div class="optimization-wrapper">
        <div class="completed">
            <div class="single-optimization-wrapper">
                <div class="optimization-title">
                    OPTIMIZATION
                </div>
                <div
                    v-for="(channel, index) in selectedPlatformDetails"
                    :key="index"
                    class="single-optimization">
                    <div class="icon">
                        <icon
                            :name="channel.icon_name"
                            color="#8F9EA6"
                            size="25" />
                    </div>
                    <span class="single-name">{{ getObjective(channel.key) }}</span>
                </div>
            </div>
            <a
                href="#"
                class="sad-edit"
                @click.prevent="handleEdit">
                edit
            </a>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Icon from '@/components/globals/Icon';
import { CAMPAIGN_OBJECTIVES } from '@/components/ad-deployment/store/constants';

export default {
    components: {
        Icon
    },
    computed: {
        ...mapState({
            optimization: state => state.adDeployment.optimization
        }),
        ...mapGetters({
            selectedPlatformDetails: 'adDeployment/selectedPlatformDetails'
        })
    },
    methods: {
        handleEdit() {
            this.$store.dispatch(
                'adDeployment/goToStepByName',
                'campaign_configuration'
            );
        },
        getObjective(channel) {
            const objectiveValue = this.optimization.platform_specific[channel].objective;

            return CAMPAIGN_OBJECTIVES[channel].find(objective => objective.platform_value === objectiveValue).ui_name;
        },
    }
};
</script>


<style lang="scss" scoped>
.optimization-wrapper {
    display: flex;
    flex-direction: column;
}

.completed {
    display: inline-flex;
    align-items: center;
}

.single-optimization-wrapper {
    width: 350px;
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;
    display: inline-flex;
    position: relative;
    padding: 50px 20px 20px 20px;
}

.single-optimization {
    margin-right: 30px;
}

.single-name {
    color: #03A2EA;
    font-style: italic;
    display: inline-block;
    margin-top: 5px;
}

.optimization-title {
    margin-bottom: 15px;
    font-weight: 700;
    position: absolute;
    top: 15px;
    left: 15px;
}
</style>
