<template>
    <step-wrapper
        :index="index"
        :active="active"
        :valid="valid"
        :step="name"
        title="How do you want to optimize this ad?"
        :help-link="helpLink"
        description="
            Choose the optimization strategy and, if necessary,
            the associated configuration for your ad(s):
        ">
        <complete v-if="!active" />
        <template v-else>
            <div class="optimization-wrapper">
                <platform-configuration
                    v-for="channel in selectedChannels"
                    :key="channel"
                    :platform="channel" />
            </div>
            <div class="button-sad-holder">
                <styled-button
                    small
                    :disabled="!valid"
                    @click="goToNextStep">
                    <span>Continue</span>
                </styled-button>
            </div>
        </template>
    </step-wrapper>
</template>

<script>
import StepWrapper from '../../components/StepWrapper';
import StyledButton from '@/components/globals/StyledButton';
import Complete from './Complete';
import PlatformConfiguration from './PlatformConfiguration';
import { mapActions, mapState } from 'vuex';
import stepProps from '../stepProps';

export default {
    name: 'CampaignConfiguration',
    components: {
        StyledButton,
        PlatformConfiguration,
        StepWrapper,
        Complete
    },
    props: stepProps,
    data() {
        return {
            buttonDisabled: true,
            stepCompleted: false,
            helpLink: 'https://support.buyerbridge.com/knowledge/how-to-ad-launcher#custom-optimize-ads'
        };
    },
    computed: {
        ...mapState({
            selectedChannels: state => state.adDeployment.platforms,
            optimization: state => state.adDeployment.optimization,
            selectedPreset: state => state.adDeployment.selectedPreset,
            currentStep: state => state.adDeployment.current_step,
        })
    },
    created() {
        if (this.selectedPreset.title != 'Custom' && this.currentStep === this.index) {
            this.goToNextStep();
        }
    },
    methods: {
        ...mapActions({
            goToNextStep: 'adDeployment/goToNextStep'
        }),
    }
};
</script>

<style lang="scss" scoped>
.optimization-wrapper{
    display: flex;
    flex-direction: column;
}
</style>