<template>
    <div class="wrapper">
        <div class="wrapper__top">
            <icon
                class="mr-4"
                :name="channelDetails.icon_name"
                color="#8F9EA6"
                size="25" />
            <v-radio-group
                v-model="objective"
                row>
                <v-radio
                    v-for="item in objectives"
                    :key="item.platform_value"
                    :label="item.ui_name"
                    :value="item.platform_value"
                    :disabled="item.disabled"
                    color="#00A3EA" />
            </v-radio-group>
            <div v-if="!(platform === PLATFORM_PINTEREST)">
                <a
                    href=""
                    @click.prevent="showAdvanced = !showAdvanced">{{ showAdvanced ? 'simple' : 'advanced' }}</a>
            </div>
        </div>
        <div
            v-if="showAdvanced"
            class="wrapper__bottom pt-3 pb-3">
            <div class="mb-4 blue-text">
                Advanced Selections (optional)
            </div>
            <div class="advanced-fields">
                <div class="field">
                    <v-select
                        v-model="optimizationGoal"
                        return-object
                        :items="optimizationGoals"
                        item-text="ui_name"
                        item-value="platform_value"
                        label="Optimization Goal"
                        class="styled-field mb-3" />
                </div>
                <div class="field">
                    <v-select
                        v-model="billingEvent"
                        return-object
                        :items="billingEvents"
                        item-text="ui_name"
                        item-value="platform_value"
                        label="Billing Event"
                        class="styled-field mb-3" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Icon from '@/components/globals/Icon';
import { mapState } from 'vuex';
import {
    BILLING_EVENTS,
    CAMPAIGN_OBJECTIVES,
    OBJECTIVE_OUTCOME_AWARENESS,
    OBJECTIVE_VIDEO_VIEW,
    OBJECTIVE_VIDEO_VIEWS,
    OPTIMIZATION_GOALS,
    THRUPLAY_DISABLED_OBJECTIVES
} from '@/components/ad-deployment/store/constants.js';
import { PLATFORM_FACEBOOK, PLATFORM_PINTEREST, PLATFORM_TIKTOK } from '@/helpers/globals';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels';

export default {
    components: {
        Icon
    },
    props: {
        platform: {
            type: String,
            required: true
        },
    },
    data() {
        return {
            showAdvanced: false,
            PLATFORM_PINTEREST
        };
    },
    computed: {
        ...mapState({
            ads: (state) => state.adDeployment.ads,
        }),
        channelDetails() {
            return getChannelByPlatformName(this.platform);
        },
        objectives() {
            if (this.allAdsAreVideos || this.platform === PLATFORM_TIKTOK) {
                return CAMPAIGN_OBJECTIVES[this.platform];
            }

            return CAMPAIGN_OBJECTIVES[this.platform].map(objective => {
                objective.disabled = [OBJECTIVE_VIDEO_VIEW, OBJECTIVE_VIDEO_VIEWS].includes(objective.platform_value);

                return objective;
            });
        },
        objective: {
            get() {
                return this.$store.state.adDeployment.optimization.platform_specific[this.platform].objective;
            },
            set(value) {
                this.$store.commit('adDeployment/setPlatformObjective', {
                    platform: this.platform,
                    value,
                });
            }
        },
        allAdsAreVideos() {
            return this.ads.filter(ad => ad.configuration?.all_platforms?.creatives[0]?.assets[0]?.asset_type?.name === 'video').length === this.ads.length;
        },
        optimizationGoals() {
            if (this.allAdsAreVideos) {
                return OPTIMIZATION_GOALS[this.platform][this.objective];
            }

            // If not all ads are videos, then we need to disable objective Thruplay
            OPTIMIZATION_GOALS[PLATFORM_FACEBOOK][OBJECTIVE_OUTCOME_AWARENESS] = THRUPLAY_DISABLED_OBJECTIVES;

            return OPTIMIZATION_GOALS[this.platform][this.objective];
        },
        optimizationGoal: {
            get() {
                return this.$store.state.adDeployment.optimization.platform_specific[this.platform].optimization_goal;
            },
            set(value) {
                this.$store.commit('adDeployment/setPlatformOptimizationGoal', {
                    platform: this.platform,
                    value,
                });
            }
        },
        billingEvents() {
            return BILLING_EVENTS[this.platform][this.optimizationGoal.platform_value];
        },
        billingEvent: {
            get() {
                return this.$store.state.adDeployment.optimization.platform_specific[this.platform].billing_event;
            },
            set(value) {
                this.$store.commit('adDeployment/setPlatformBillingEvent', {
                    platform: this.platform,
                    value,
                });
            }
        }
    },
};
</script>
<style lang="scss" scoped>
.v-radio {
    margin-bottom: 10px;
}

.wrapper {
    min-width: 200px;
    margin-bottom: 10px;
    background: white;
    border: 1px solid #DFDFE0;
    border-radius: 5px;

    &__top {
        padding: 15px;
        padding-left: 20px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #DFDFE0;
    }

    &__bottom {
        padding-left: 30px;
        padding-right: 30px;
    }
}

.description {
    margin-bottom: 15px;
    color: #494949;
    font-weight: 700;
}

.cta-select {
    max-width: 350px;
}

.cta-wrapper {
    display: flex;
    align-items: center;
    padding: 30px;
}

.cta-text {
    color: #FF3D00;
    font-size: 12px;
}

.field {
    max-width: 250px;
}

.advanced-fields {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
    align-items: center;
}

.blue-text {
    color: #03A2EA;
    display: inline-block;
}
</style>
